import { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'App.css';

// contexts
import { StepContext } from 'contexts/StepContext';
import GlobalContext from 'contexts/GlobalContext';
import { checkParkingBillAPI } from 'utils/fetchAPI';

// types & hooks
import { BillDetail } from 'types';
import useQuery from 'hooks/useQuery';

// components
import Button from "components/Button";


const BillConfirm = () => {

    const KINMEN_URL = process.env.REACT_APP_KINMEN_URL as string;
    const TAITUNG_URL = process.env.REACT_APP_TAITUNG_URL as string;
    const PINGTUNG_URL = process.env.REACT_APP_PINGTUNG_URL as string;
    const CHIAYI_URL = process.env.REACT_APP_CHIAYI_URL as string;

    const navigate = useNavigate();
    const goToBack = () => {
        navigate(-1);
    };

    const { nextStep } = useContext(StepContext);
    const { billDetail, updateBillDetail, isLoading, setIsLoading, fetchInformation, setIsAppleDevice } = useContext(GlobalContext);

    const query = useQuery();
    const area = query.get('area_code');
    const license = query.get('bill_licenseplate');
    const bills = query.get('bills_no');

    const [message, setMessage] = useState<string>('');

    const getData = async () => {
        const option = {
            "bill_type": "bill_spot",
            "area_code": area,
            "bill_licenseplate": license,
            "bills_no": bills
        }
        setIsLoading(true);
        try {
            const response = await checkParkingBillAPI(option);
            const responseData = await response.json() as BillDetail;
            if (response.ok) {
                // console.log('查詢資料成功', responseData);
                updateBillDetail(responseData);
                localStorage.setItem('bill_id', responseData.bill_id);
                if (billDetail.bill_amount === 0) {
                    setMessage('已完成繳費');
                } else {
                    setMessage('');
                }
            } else {
                // console.log('查詢資料失敗', responseData);
                toast.error(`查詢失敗：${responseData.error_message}`);
                setMessage('查詢失敗');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error(`err : ${error}`);
            setMessage('伺服器錯誤');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // parkingInformation
    useEffect(() => {
        fetchInformation({ area_id: area });
    }, [area, fetchInformation]);

    // Apple Pay
    useEffect(() => {
        const ua = navigator.userAgent;
        const iOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios
        const android = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; // android
        const isSafari = /^((?!chrome|android).)*safari/i.test(ua);

        if (iOS || isSafari) {
            setIsAppleDevice(true);
        } else if (android) {
            console.log('android device');
            setIsAppleDevice(false);
        } else {
            console.log('not support Apple pay');
            setIsAppleDevice(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderSearch = () => {
        if (area === '19') {
            return <Link to={KINMEN_URL}>
                <Button title="重新查詢" />
            </Link>
        } else if (area === '15') {
            return <Link to={TAITUNG_URL}>
                <Button title="重新查詢" />
            </Link>
        } else if (area === '8') {
            return <Link to={PINGTUNG_URL}>
                <Button title="重新查詢" />
            </Link>
        } else if (area === '17') {
            return <Link to={CHIAYI_URL}>
                <Button title="重新查詢" />
            </Link>
        }
    }


    return (
        <>
            {
                billDetail?.bill_amount === 0 && !isLoading ?
                    <div className="p-4 pt-5 text-center">
                        <h2 className="text-lg mb-10 mt-8 leading-4r text-center w-fit mx-auto font-bold">{message}</h2>
                        <div className="w-full text-center mt-10">
                            {renderSearch()}
                        </div>
                    </div>
                    :
                    <div className="p-4 pt-5 text-center" >
                        <h2 className="text-lg mb-5 leading-4r text-center w-fit mx-auto font-bold">確認繳費資料</h2>
                        {
                            billDetail.bill_detail.length !== 0 &&
                            <>
                                <div className="w-60 mx-auto mb-10">
                                    <p className="text-sm leading-4r text-start pl-6 mb-5">單號（共 {billDetail.bill_detail.length} 筆）：</p>

                                    <div className="w-60 max-h-48 mx-auto bg-gray-lighter mb-5 overflow-y-auto rounded-md pl-2 scrollbar-container py-2">
                                        {
                                            billDetail.bill_detail?.map((item: any) => (
                                                <div key={item.bill_no}
                                                    className={`grid grid-cols-3 text-left px-4 py-2 text-sm gap-3 
                                            ${item.bill_no.startsWith('Z') && 'text-red-500'}`}
                                                >
                                                    <div className='col-span-2'>{item.bill_no}</div>
                                                    <div>{item.bill_amount} 元</div>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <p className="text-sm leading-4r text-start pl-6 mb-3">車號：{billDetail.bill_licenseplate?.toUpperCase()}</p>
                                    <p className="text-sm leading-4r text-start pl-6">總金額：{billDetail.bill_amount} 元</p>
                                </div>
                                <div className='relative lg:w-3/12 w-4/5 md:w-6/12 sm:w-2/4 m-auto'>
                                    <div className="w-full text-center">
                                        {billDetail.payment_status === 1 ?
                                            <div className='font-bold text-primary w-24 m-auto
                                    px-2.5 py-1.5 rounded text-center bg-gray-lighter border-gray-lighter'>
                                                已繳費
                                            </div>
                                            :
                                            <Button title="確認繳費" onClick={() => nextStep()} />}
                                    </div>
                                    <div className='md:max-w-[658px] max-w-[320px] mx-auto absolute top-1/2 left-0' style={{ transform: "translateY(-50%)" }}>
                                        <i className="fa-solid fa-chevron-left cursor-pointer text-primary" onClick={goToBack} ></i>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
            }
        </>
    )
}

export default BillConfirm;