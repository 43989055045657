export default function directCardSetup() {

  if (!!document.getElementById('card-number')?.innerHTML) return

  let fields = {
    number: {
      // css selector
      element: '#card-number',
      placeholder: '•••• •••• •••• ••••',
    },
    expirationDate: {
      // DOM object
      element: document.getElementById('card-expiration-date'),
      placeholder: 'MM / YY'
    },
    ccv: {
      element: '#card-ccv',
      placeholder: 'CCV'
    }
  }


  window.TPDirect.card.setup({
    // Display ccv field

    fields: fields,
    styles: {
      // Style all elements
      'input': {
        'color': '#999999'
      },
      // Styling ccv field
      'input.ccv': {
      },
      // Styling expiration-date field
      'input.expiration-date': {
      },
      // Styling card-number field
      'input.card-number': {
        'letter-spacing': '1.2px'
      },
      // style focus state
      ':focus': {
        // 'color': 'black'
      },
      '::placeholder': {
        'color': '#AAAAAA'
      },
      // style valid state
      '.valid': {
        'color': '#999999',
      },
      // style invalid state
      '.invalid': {
        'color': 'red'
      },
      // Media queries
      // Note that these apply to the iframe, not the root window.
      // '@media screen and (max-width: 400px)': {
      //   'input': {
      //     'color': 'orange'
      //   }
      // }
    },
    isMaskCreditCardNumber: true,
    maskCreditCardNumberRange: {
      beginIndex: 4,
      endIndex: 11
    }
  })

}