import React, { useContext, useEffect, useState } from 'react';
// import { toast } from 'react-toastify';
import Button from 'components/Button'
import { StepContext } from 'contexts/StepContext';
import { GlobalContext } from 'contexts/GlobalContext';
import { BillDetail, BillPaymentStatus } from 'types';
import moment from 'moment';
import FindCarGif from '../images/find-car.gif';
import NoCarPhoto from '../images/not-found-car-photo.svg'
import { findCarAPI } from 'utils/fetchAPI';

interface Props extends BillDetail {
  resetBillLotCode: () => void;
}

const SearchCarConfirm = ({
  bill_licenseplate,
  bill_amount,
  resetBillLotCode,
  payment_status,
  lot_entering_time,
  // bill_parking_hour,
  // bill_rate,
  // bill_id,
  // bill_lot_code,
  // bill_token,
}: Props) => {
  const [showCar, setShowCar] = useState<boolean>(false);
  const [showImg, setShowImg] = useState<boolean>(true);
  const [carImg, setCarImg] = useState<string>('');
  const [carGrid, setCarGrid] = useState<string>('');

  const { nextStep } = useContext(StepContext)
  const { removeBillDetail, parkingInformation, setIsLoading } = useContext(GlobalContext)
  const goToBack = () => {
    removeBillDetail();
    resetBillLotCode();
    setCarImg('');
    setCarGrid('');
    setShowCar(false);
  };
  //避免現在時間被人為延遲，設定每十分鐘refresh一次
  useEffect(() => {
    // 關閉遮罩
    setIsLoading(false);
    // 啟動計時器
    const timer = setTimeout(() => {
      goToBack()
    }, 600000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //拿到現在時間，並調整為需要格式，e.g. 111/01/02 9:30
  var nowDay = new Date().toISOString();
  var nowTime = new Date().toTimeString();
  const formatDate = (_day: string, _time: string) => {
    const [year, month, day] = _day.split('-');
    let newDay = `${year}-${month}-${day.slice(0, 2)}`;
    const [hour, time] = _time.split(':');
    let newTime = `${hour}:${time}`;
    const newsDate = `${newDay} ${newTime}`;
    return newsDate;
  };
  let display_nowDate = formatDate(nowDay, nowTime);
  let display_enteringDate = formatDate(lot_entering_time.trim().split(" ")[0], lot_entering_time.trim().split(" ")[1]);
  //拿到進場時間，並調整為需要格式，e.g. 111/01/02 9:30

  // 目前停放時數
  const [parkingTime, setParkingTime] = useState(0);
  useEffect(() => {
    // var enteringTime = new Date(lot_entering_time);
    // var nowDate = new Date();
    // 時間互相相減 以分鐘為單位
    // let differ = (nowDate.getTime() - enteringTime.getTime()) / (1000 * 60);

    const enteringTime = moment(lot_entering_time).valueOf();
    const nowDate = moment.now();
    const differ = (nowDate - enteringTime) / (1000 * 60);
    let parking_min = Math.ceil(differ);
    setParkingTime(parking_min);
  }, [lot_entering_time]);

  const renderBillSatus = () => {
    if (payment_status === BillPaymentStatus.Paid) {
      return <span className='text-green-700 font-bold'>
        已繳費
      </span>
    } else if (bill_amount === 0) {
      return <span className='text-primary font-bold'>
        無須繳費
      </span>
    } else {
      return <span className='text-red-500 font-bold'>
        未繳費
      </span>
    }
  }

  const handleFindCar = async () => {
    const option = {
      "plate": bill_licenseplate,
      "find_car_url": parkingInformation?.find_car_url
    }
    try {
      const response = await findCarAPI(option);
      const result = await response.json();

      // if result.value
      if (response.ok) {
        const { image_url, image_context } = result;
        const currentImageContext = (image_context === '' || image_context === null || image_context === undefined) ? image_url : `data:image/jpeg;base64,${image_context}`;

        // console.log('image_context', image_context);
        // console.log('image_url', image_url);
        // console.log('currentImageContext', currentImageContext);

        setCarImg(currentImageContext);
        setCarGrid(result.spot_no);
        setShowCar(true);
        // 是特定場域但皆沒有圖片來源
        if (!result.image_url && !result.image_context) {
          setShowImg(false);
        } else {
          setShowImg(true);
        }
      } else {
        // toast.error('車輛照片失敗');
        setShowImg(false);
        setShowCar(true);
      }
    } catch (err) {
      // toast.error('伺服器錯誤');
      setShowImg(false);
      setShowCar(true);
    }
  }

  useEffect(() => {
    // console.log(bill_licenseplate);
    if (parkingInformation?.find_car_url) {
      handleFindCar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="p-4">
    <h1 className="flex my-6 text-lg justify-center flex-grow font-bold">停車資訊</h1>
    <div className="flex  md:max-w-[826px] max-w-[360px]  mx-auto mb-8 border rounded-xl border-primary">
      <div className="flex w-full flex-col pt-8 pb-8 gap-8 md:max-w-[658px] mx-auto items-center md:flex-row md:items-start md:gap-10">
        <div className="flex-1 w-5/6 md:w-full">
          <p className='mb-5'>您的車牌：&emsp;{bill_licenseplate}</p>
          {/* {!!bill_parking_hour && <p className='mb-4'>停車時數：{bill_parking_hour} 小時</p>}
          {!!bill_rate && <p className='mb-4'>停車費率：{bill_rate} 元/小時</p>} */}
          <p className='mb-5'>進場時間：&emsp;{display_enteringDate}</p>
          <p className='mb-5'>現在時間：&emsp;{payment_status === BillPaymentStatus.UnPaid ? display_nowDate : '-'}</p>
          <p className='mb-5'>停放時數：&emsp;{payment_status === BillPaymentStatus.UnPaid ? parkingTime : '-'} 分鐘</p>
          <p className='mb-5'>繳費狀態：&emsp;{renderBillSatus()}</p>
          {
            (payment_status === BillPaymentStatus.UnPaid) &&
            <>
              <hr className="border mt-6 mb-6 w-full border-gray" />
              <div className="flex justify-center">
                <span className='text-primary'>▲</span>&ensp;
                <span className='inline-block font-bold'>本次停車費用：{bill_amount} 元</span>
              </div>
            </>
          }
        </div>
        {
          // 特定場域才有反向尋車
          parkingInformation?.find_car_url &&
          <>
            {showCar ?
              <>
                {showImg ?
                  <div className="flex-1 w-5/6 md:w-full">
                    <div className='w-full h-56 overflow-hidden mb-4'>
                      <img className='w-full h-full object-cover object-center mx-auto' src={carImg} alt={bill_licenseplate} />
                    </div>
                    <p className='text-center text-primary font-bold pt-2'>停放車格：{carGrid}</p>
                    {/* <p className='text-center text-xs mt-1'>施工期間，車格號以照片為主</p> */}
                  </div>
                  :
                  // 無車輛照片
                  <div className="flex-1 w-5/6 md:w-full">
                    <div className='w-full h-56 overflow-hidden mb-4 flex justify-center	items-end'>
                      <img className='h-5/6 object-cover object-center' src={NoCarPhoto} alt={bill_licenseplate} />
                    </div>
                  </div>
                }
              </>
              :
              // 讀取中GIF
              <div className="flex-1 w-5/6 md:w-full">
                <div className='w-full h-[260px] overflow-hidden'>
                  <img className='w-full h-full object-cover object-center' src={FindCarGif} alt="find car" />
                </div>
              </div>
            }
          </>
        }
      </div>
    </div>
    {
      (payment_status === BillPaymentStatus.UnPaid && bill_amount !== 0) ?
        <div className='relative lg:w-3/12 w-4/5 md:w-6/12 sm:w-2/4 m-auto mb-20'>
          <div className="w-full text-center">
            <Button title="確認" onClick={() => nextStep()} />
          </div>
          <div className='md:max-w-[826px] max-w-[260px] mx-auto absolute top-1/2 left-0' style={{ transform: "translateY(-50%)" }}>
            <i className="fa-solid fa-chevron-left cursor-pointer text-primary" onClick={goToBack} ></i>
          </div>
        </div>
        :
        <div className='relative lg:w-3/12 w-4/5 md:w-6/12 sm:w-2/4 m-auto mb-20'>
          <div className="w-full text-center">
            <Button title="重新查詢" onClick={() => goToBack()} />
          </div>
        </div>
    }
  </div>;
}
export default SearchCarConfirm;
