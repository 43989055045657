const PARKING_BILL_URL = process.env.REACT_APP_PARKING_BILL_URL;
const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL;
const CHECK_PAYMENT_URL = process.env.REACT_APP_CHECK_PAYMENT_URL;
const INFORMATION_URL = process.env.REACT_APP_INFORMATION_URL;
const RENT_BILL_URL = process.env.REACT_APP_RENT_BILL_URL;
const LOT_INFO_URL = process.env.REACT_APP_LOT_INFO_URL;
const FIND_CAR_URL = process.env.REACT_APP_FIND_CAR_URL;

export async function findCarAPI(data: any) {
  return fetchAPI(FIND_CAR_URL, data);
}

export async function searchAreaAPI(data: any) {
  return fetchAPI(LOT_INFO_URL, data);
}

export async function checkParkingBillAPI(data: any) {
  return fetchAPI(PARKING_BILL_URL, data);
}

export async function confirmPaymentAPI(data: any) {
  return fetchAPI(PAYMENT_URL, data);
}

export async function checkPaymentStatusAPI(data: any) {
  return fetchAPI(CHECK_PAYMENT_URL, data);
}

export async function fetchInformationAPI(data: any) {
  return fetchAPI(INFORMATION_URL, data);
}

export async function checkRentBillAPI(data: any) {
  return fetchAPI(RENT_BILL_URL, data);
}

async function fetchAPI(endpoint: string, data: any) {
  const response = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json'
    },
  });

  return response;
}
