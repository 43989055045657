import React from 'react';
interface Props {
  value: any,
  name: string,
  id: string,
  className?: string,
  defaultChecked?: boolean,
  title: string | React.ReactNode,
}
const Radio = ({ value, name, title, className, defaultChecked, id }: Props) => {
  return <label
    htmlFor={id}
    className={`pl-6 mr-1.5 block text-gray-label relative cursor-pointer ${className && className}`}   >
    {title}
    <input
      id={id}
      name={name}
      defaultChecked={defaultChecked}
      className="absolute opacity-0 cursor-pointer radioInput"
      type="radio"
      value={value} />
    <span className="radioInput__checkmark"></span>
  </label>
}
export default Radio;