import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import "./index.css"
import App from './App';
import NotFound from './containers/NotFound';
import reportWebVitals from './reportWebVitals';
import SystemError from 'containers/SystemError';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path=":type/*" element={<App />} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="system-error" element={< SystemError />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
