import { useContext, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from 'components/Button'
import { GlobalContext } from 'contexts/GlobalContext';
import SearchCarConfirm from './SearchCarConfirm';
import { ReactComponent as Warning } from 'images/warningBlue.svg'
import { useForm } from "react-hook-form";
import { checkParkingBillAPI } from 'utils/fetchAPI';
import { BillDetail } from 'types/BillDetail';
import { ErrorResponse } from 'types/ErrorMessage';
import { ReactComponent as LinePayIcon } from '../images/line-pay.svg';
import { ReactComponent as DirectPayIcon } from '../images/direct-pay.svg';
import { ReactComponent as ApplePayIcon } from '../images/apple-pay.svg';
import { ReactComponent as TaiwanPayIcon } from '../images/taiwan-pay.svg';

type SearchForm = {
  bill_licenseplate: string;
  bill_lot_code: string;
};

const SearchCar = () => {
  const { code } = useParams() as { code: string };
  const { billDetail, updateBillDetail, setIsLoading, fetchInformation, isAppleDevice, setIsAppleDevice, parkingInformation } = useContext(GlobalContext);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<SearchForm>({ defaultValues: { bill_lot_code: code } });

  // show Apple Pay icon
  useEffect(() => {
    const ua = navigator.userAgent;
    const iOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios
    const android = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; // android
    const isSafari = /^((?!chrome|android).)*safari/i.test(ua);

    if (iOS || isSafari) {
      setIsAppleDevice(true);
    } else if (android) {
      console.log('android device');
      setIsAppleDevice(false);
    } else {
      console.log('not support Apple pay');
      setIsAppleDevice(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetBillLotCode = useCallback(
    () => {
      setValue("bill_licenseplate", '');
    },
    [setValue],
  )

  const onSubmit = async (data: SearchForm) => {
    setIsLoading(true);

    try {
      const response = await checkParkingBillAPI({
        ...data,
        bill_licenseplate: data.bill_licenseplate.toUpperCase(),
      });
      if (!response.ok) {
        const { error_message: errorMessage } = await response.json() as ErrorResponse;
        toast.error(errorMessage)
      } else {
        const result = await response.json() as BillDetail;
        localStorage.setItem('exit_time', result.lot_allow_exit_time);
        localStorage.setItem('bill_id', result.bill_id);
        updateBillDetail(result);
      }
    } catch (error) {
      console.error(error);
      toast.error('伺服器錯誤');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInformation({ lot_code: code });
  }, [code, fetchInformation]);

  if (billDetail?.bill_licenseplate) {
    return <SearchCarConfirm {...billDetail} resetBillLotCode={resetBillLotCode} />;
  }

  return (
    <div className="p-4 pt-5 text-center" >

      <h2 className="text-xs mb-5 leading-4r text-center w-fit mx-auto ">請輸入車牌號碼查詢</h2>

      <form className='mb-[27px]' onSubmit={handleSubmit(onSubmit)} >

        <input
          {...register("bill_licenseplate", {
            setValueAs: value => value.trim(),
            required: true,
            pattern: /^\S+-\S+$/,
          })}
          placeholder="範例：ABC-1234"
          className={`py-3 px-4 border uppercase
          rounded block max-w-xl w-full mx-auto outline-0 first-letter transition-colors
          ${errors.bill_licenseplate ? 'border-error' : 'border-gray-300'}`} />

        {errors.bill_licenseplate && <span className='max-w-xl mx-auto block mt-1 text-error'>請輸入車牌號碼</span>}

        <Button type="submit" className="mt-12 mx-auto block" title="送出" />

      </form>

      <div className='flex mx-auto justify-center items-center'>

        <Warning width="18px" height="18px" />
        <p className='text-left text-primary ml-1 text-xs'>
          行動繳費網頁不提供紙本發票及統編服務，<br />
          若有相關需求請至<span className='text-important'>實體繳費機</span>繳費。
        </p>
      </div>
      <div className='flex mx-auto justify-center items-center flex-col mt-5'>
        <h4 className='mb-2'>繳費管道</h4>
        <DirectPayIcon className='mb-1.5' />
        <div className='flex items-center gap-2'>
          {parkingInformation.is_line_pay === 1 && <LinePayIcon />}
          {(isAppleDevice && parkingInformation.is_apple_pay === 1) && <ApplePayIcon />}
          {parkingInformation.is_taiwan_pay === 1 && <TaiwanPayIcon />}
        </div>
      </div>
    </div>
  );
}
export default SearchCar;



