import { ApplicationType } from "types";

export enum CitySteps {
  SearchBill,
  BillConfirm,
  SelectPayment,
  ResultView,
}

export enum PaymentSteps {
  SearchCar,
  SelectPayment,
  ResultView,
}

export enum RentSteps {
  InputRentData,
  SelectPayment,
  ResultView,
}

export interface Step {
  step: PaymentSteps | RentSteps | CitySteps,
  title: string,
  path?: string,
  processPaths?: string[],
}

export interface Steps extends Array<Step> { }

export const CITY_STEP_LIST: Step[] = [
  {
    step: CitySteps.SearchBill,
    title: '車牌查詢',
    path: ''
  },
  {
    step: CitySteps.BillConfirm,
    title: '勾選繳費',
    // used for mapping path with steps.
    processPaths: ['/bill-confirm'],
    path: '/bill-confirm'
  },
  {
    step: CitySteps.SelectPayment,
    title: '線上繳費',
    // used for mapping path with steps.
    processPaths: ['/select-payment','/result'],
    path: '/select-payment'
  },
  {
    step: CitySteps.ResultView,
    title: '領取憑證',
    processPaths: ['/success','/failed'],
    path:''
  }
]

export const PAYMENT_STEP_LIST = [
  {
    step: PaymentSteps.SearchCar,
    title: '車牌查詢',
    // path: '/search-license'
    // processPaths: ['/search-car', '/search-license'],
    path: '/search-car'
  },
  {
    step: PaymentSteps.SelectPayment,
    title: '線上繳費',
    // used for mapping path with steps.
    processPaths: ['/select-payment', '/result'],
    path: '/select-payment'
  },
  {
    step: PaymentSteps.ResultView,
    title: '領取憑證',
    processPaths: ['/success', '/failed'],
    path: ''
  }
]

export const RENT_STEP_LIST = [
  {
    step: RentSteps.InputRentData,
    title: '個人身份填寫',
    path: '/check-rent'
  },
  {
    step: RentSteps.SelectPayment,
    title: '線上繳費',
    // used for mapping path with steps.
    processPaths: ['/select-payment', '/result'],
    path: '/select-payment'
  },
  {
    step: RentSteps.ResultView,
    title: '領取憑證',
    processPaths: ['/success', '/failed'],
    path: ''
  }
]

interface TypeMapping {
  [key: string]: Steps;
}

export const typeMapping: TypeMapping = {
  [ApplicationType.City]: CITY_STEP_LIST,
  [ApplicationType.Pay]: PAYMENT_STEP_LIST,
  [ApplicationType.Rent]: RENT_STEP_LIST,
}

export enum PaymentGateway {
  DirectPay = 'card',
  LinePay = 'line_pay',
  ApplePay = 'apple_pay',
  TaiwanPay = 'taiwan_pay',
}
