import React from 'react';
import {
  Steps,
  PaymentSteps,
  RentSteps,
  CitySteps
} from 'constants/steps';
// import { ReactComponent as Search } from 'images/search.svg';
// import { useLocation } from 'react-router-dom';


const Divider = () => {
  return (
    <div
      className="mx-3"
      style={{
        width: '116px',
        height: '2px',
        background: '#E0E0E0',
        marginTop: '8px'
      }} />)
}


interface Props {
  stepList: Steps,
  currentStep: PaymentSteps | RentSteps | CitySteps,
}

const Progress = ({ stepList, currentStep }: Props) => {

  // const location = useLocation();
  // const pathname = location.pathname.split('/')[2] as string;
  // const pathcode = location.pathname.split('/')[3] as string;

  if (!stepList) return null
  const stepCount = stepList.findIndex(({ step }) => step === currentStep);

  const steps = stepList.map<React.ReactNode>(
    (step, index) => (
      <div className="text-center relative flex justify-center" key={step.step}>
        <div className={
          `rounded-full d-inline-block text-primary
          w-5 h-5 text-xs leading-5 mx-auto text-cente
          ${index > stepCount ? 'bg-gray-deep' : 'bg-white'}`}
        >
          {index < stepCount || stepCount === 3 ? '✓' : index + 1}
        </div>

        <p className={`absolute top-8 w-max text-xs  ${index > stepCount ? 'text-gray-deep' : 'text-white'}`} >{step.title}</p>
      </div>
    )
  )

  return <div className='pt-5 pb-12 flex justify-center items-top px-8 border-b-4 border-gray-300 bg-primary progressBg'>
    {
      // 萬芳醫院
      // pathcode === '76303216' && pathname === 'search-car' ?
      //   <div className="text-center relative flex justify-center">
      //     <div className="d-inline-blockw-5 h-5 leading-5 mx-auto text-center"></div>
      //     <p className="absolute top-2 w-max text-lg text-white tracking-wider font-bold flex items-center gap-2">
      //       <Search width="24px" height="24px" />
      //       請選擇功能項目
      //     </p>
      //   </div>
      //   :
        <>
          {steps.reduce((prev, curr) => [prev, <Divider key={`div-${steps.length}`} />, curr])}
        </>
    }

  </div>;
}
export default Progress;