import { useContext, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import { ApplicationType } from 'types';
import { StepContext } from 'contexts/StepContext';
import Button from 'components/Button';
import { typeMapping } from 'constants/steps';
import { ReactComponent as ErrorWithX } from '../images/error-with-x.svg';

const ResultFailed = () => {

  const KINMEN_URL = process.env.REACT_APP_KINMEN_URL as string;
  const TAITUNG_URL = process.env.REACT_APP_TAITUNG_URL as string;
  const PINGTUNG_URL = process.env.REACT_APP_PINGTUNG_URL as string;
  const CHIAYI_URL = process.env.REACT_APP_CHIAYI_URL as string;
  const lot_code = localStorage.getItem('lot_code') as string;
  const origin = window.location.origin;

  const { type = ApplicationType.Pay } = useParams();
  const { setCurrentStep } = useContext(StepContext);
  const stepList = typeMapping[type];

  useEffect(() => {
    localStorage.setItem('bill_id', '');
  }, []);
  
  useEffect(() => {
    setCurrentStep(stepList[stepList.length - 1].step);
  }, [stepList, setCurrentStep])

  const renderSearch = () => {
    if (type === ApplicationType.City) {
      if (lot_code === '19') {
        return <Link to={KINMEN_URL}>
          <Button title="返回繳費" />
        </Link>
      } else if (lot_code === '15') {
        return <Link to={TAITUNG_URL}>
          <Button title="返回繳費" />
        </Link>
      } else if (lot_code === '8') {
        return <Link to={PINGTUNG_URL}>
          <Button title="返回繳費" />
        </Link>
      } else if (lot_code === '17') {
        return <Link to={CHIAYI_URL}>
          <Button title="返回繳費" />
        </Link>
      }
    } else {
      return <Link to={`${origin}/${type}${typeMapping[type][0].path}/${lot_code}`}>
        <Button title="返回繳費" />
      </Link>
    }
  }

  return <div className="p-4 pt-[100px] text-center h-[calc(100vh-132px)]">

    <ErrorWithX className='w-[40px] h-[40px] mx-auto mb-5' />
    <h3 className='mb-6 text-error text-[20px]'>交易失敗</h3>
    <p className='mb-12'> 很抱歉，您的交易未成功，
      <br /> 請返回繳費頁面重新繳費，謝謝！
    </p>
    <div className='text-center max-w-[772px] flex justify-center mx-auto items-center'>
      {renderSearch()}
    </div>
  </div>;
}
export default ResultFailed;