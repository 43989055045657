import { forwardRef } from 'react'

interface Props {
  label?: string,
  name: string,
  placeholder?: string,
  className?: string,
  inputClassName?: string,
  labelClassName?: string,
  required?: boolean,
  error?: object,
  noLabel?: boolean,
  disabled?: boolean,
  defaultValue?: string,
  value?: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const InputField = forwardRef<HTMLInputElement, Props>(
  ({
    name,
    label,
    onChange,
    onBlur,
    className,
    inputClassName,
    labelClassName,
    defaultValue,
    required,
    placeholder,
    error,
    noLabel = false,
    disabled = false,
    value
  }: Props, ref) => {

    return <div className={`flex items-center max-w-xs text-[12px] ${className}`}>

      {
        !noLabel && <>
          <span className={`text-error w-5 h-5 flex items-center justify-center mr-1 ${labelClassName}`}>
            {required && '＊'}
          </span>

          {
            label && <label htmlFor="" className={
              `${error ? 'text-error' : 'text-gray-label'} ${labelClassName}`}>
              {`${label}：`}
            </label>
          }
        </>
      }

      <input
        ref={ref}
        disabled={disabled}
        name={name}
        type="text"
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={value}
        className={
          `${error ? 'border-error' : 'border-gray-400'}
          pl-1 outline-none border-b 
          flex-1 disabled:bg-white
          text-gray-label ${inputClassName} w-[142px]`
        }
        onChange={onChange}
        onBlur={onBlur} />
    </div>;
  }
)
export default InputField;