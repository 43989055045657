import React, { MouseEventHandler } from 'react';

const Button = (
  { title,
    onClick,
    className,
    type,
    disabled,
    ...props
  }: {
    title: string,
    onClick?: MouseEventHandler,
    className?: string,
    type?: string,
    disabled?: boolean,
    props?: any
  }) => {
  return <button
    disabled={disabled}
    className={`px-2.5 py-1.5 bg-primary rounded text-center text-white cursor-pointer 
      hover:bg-primaryDark transition-colors ${className} ${disabled && 'disabled:bg-primaryLight  disabled:cursor-not-allowed'}`}
    onClick={onClick}
    {...props}> {title} </button>;
}
export default Button;