import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';

import Button from 'components/Button'
import { GlobalContext } from 'contexts/GlobalContext';
import { checkRentBillAPI } from 'utils/fetchAPI';
import InputField from 'components/InputField';
import { ReactComponent as Warning } from 'images/warningBlue.svg'
import SearchRentConfirm from './CheckRentConfirm';
import { ErrorResponse, BillDetail, BillPaymentStatus } from 'types';

type SearchForm = {
  user_id: string;
  name: string;
  license_plate: string;
};

const SearchRent = () => {
  const { code } = useParams() as { code: string };
  const {
    billDetail,
    updateBillDetail,
    setIsLoading,
    parkingInformation,
    fetchInformation,
  } = useContext(GlobalContext);
  const { register, handleSubmit, formState: { errors } } = useForm<SearchForm>({ defaultValues: {} });
  const {
    userid_column_name: userColumn,
    index_page_note: pageNote,
  } = parkingInformation;

  const onSubmit = async (data: SearchForm) => {
    setIsLoading(true);
    try {
      const response = await checkRentBillAPI({
        ...data,
        license_plate: data.license_plate.toUpperCase(),
      });

      setIsLoading(false);
      if (!response.ok) {
        const { error_message: errorMessage } = await response.json() as ErrorResponse;
        toast.error(errorMessage);
        return;
      }

      const result = await response.json() as BillDetail;
      localStorage.setItem('bill_id', result.bill_id);

      if (result.payment_status === BillPaymentStatus.NotAvailable) {
        toast.error('已超過繳費期限');
        return;
      }

      updateBillDetail({
        ...result,
        operator_code: code,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInformation({ operator_code: code });
  }, [code, fetchInformation]);

  if (billDetail?.bill_licenseplate) {
    return <SearchRentConfirm {...billDetail} userColumn={userColumn} />;
  }

  return (
    <div className="p-4 pt-5 text-center" >

      <h2 className="mb-5 leading-5 text-center">請完整填寫所需資料</h2>

      <form className='mb-[27px] mt-14 w-[270px] mx-auto ' onSubmit={handleSubmit(onSubmit)} >
        <InputField
          {...register("user_id", {
            required: `請輸入您的${userColumn}`,
            setValueAs:value => value.trim(),
          })}
          label={`您的${userColumn}`}
          placeholder={`請輸入您的${userColumn}`}
          className='mb-4 max-w-none overflow-hidden'
          labelClassName='break-keep shrink-0'
          error={errors.user_id}
        />
        <InputField
          {...register("name", {
            required: '請輸入您的姓名',
            setValueAs:value => value.trim(),
          })}
          label='您的姓名'
          placeholder='請輸入您的姓名'
          className='mb-4 max-w-none'
          labelClassName='break-keep shrink-0'
          error={errors.name}
        />
        <InputField
          {...register("license_plate", {
            required: '請輸入您的車牌號碼',
            pattern: /^\S+-\S+$/,
            setValueAs:value => value.trim(),
          })}
          label='車牌號碼'
          placeholder='請輸入您的車牌號碼'
          className=' max-w-none'
          inputClassName='uppercase'
          labelClassName='break-keep shrink-0'
          error={errors.license_plate}
        />

        <Button type="submit" className="mt-12 mx-auto block" title="送出" />

      </form>

      <div className='inline-grid grid-cols-[18px_1fr] mx-auto justify-center items-center'>
        <Warning width="18px" height="18px" />
        <p className='text-left text-primary ml-1 text-xs'>
          行動繳費網頁不提供紙本發票及統編服務，<br />
          若有相關需求請至<span className='text-important'>實體繳費機</span>繳費。
        </p>
        <div></div>
        <div className='mt-2 ml-1 text-important text-xs text-left'>{pageNote}</div>
      </div>

    </div>
  );
}
export default SearchRent;