import { createContext } from 'react'
import { PaymentSteps, RentSteps, CitySteps } from 'constants/steps'
interface stepContextInterface {
  currentStep: PaymentSteps | RentSteps | CitySteps,
  setCurrentStep: (arg: PaymentSteps | RentSteps | CitySteps) => void
  nextStep: () => void
}

export const StepContext = createContext<stepContextInterface>({
  currentStep: PaymentSteps.SearchCar,
  setCurrentStep: (arg: PaymentSteps | RentSteps | CitySteps) => { },
  nextStep: () => { }
}) 


export default StepContext