import { useEffect, useState, useCallback } from 'react'
import { ToastContainer } from 'react-toastify';
import { Routes, Route, useParams, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { GlobalContext } from 'contexts/GlobalContext';
import Navbar from './components/Navbar';
import Progress from './components/Progress';
import { Step } from 'constants/steps'
import { StepContext } from './contexts/StepContext'
import SelectPayment from './containers/SelectPayment';
import SearchCar from './containers/SearchCar';
import ResultSuccess from './containers/ResultSuccess';
import ResultFailed from './containers/ResultFailed';
import Result from './containers/Result';
import Loading from 'components/Loading';
import Footer from 'components/Footer';
import CheckRent from './containers/CheckRent';
import 'react-toastify/dist/ReactToastify.css';
import { ApplicationType, ParkingInformation, BillDetail, BillPaymentStatus } from 'types';
import {
  PaymentSteps,
  RentSteps,
  CitySteps,
  typeMapping
} from 'constants/steps';
import { fetchInformationAPI, searchAreaAPI } from 'utils/fetchAPI';
import BillConfirm from 'containers/BillConfirm';
// import SelectDevice from 'containers/SelectDevice';


function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAppleDevice, setIsAppleDevice] = useState(false);
  const [billDetail, setBillDetail] = useState<BillDetail>({
    bill_id: '',
    bill_licenseplate: '',
    bill_parking_hour: 0,
    bill_rate: 0,
    bill_amount: 0,
    bill_lot_code: '',
    bill_token: '',
    payment_status: 0,
    user_id: '',
    name: '',
    operator_code: '',
    bill_lot_name: '',
    lot_entering_time: '',
    common_payment_info: {},
    bill_detail: [],
    error_message: '',
    lot_allow_exit_time: '',
  });
  const [parkingInformation, setInformation] = useState<ParkingInformation>({
    lot_name: '',
    lot_phone: '',
    operator: '',
    index_page_note: '',
    after_paying_page_note: '',
    userid_column_name: '',
    app_id: 0,
    app_key: '',
    merchant_identifier: '',
    is_line_pay: 0,
    is_apple_pay: 0,
    is_taiwan_pay: 0,
    area_id: 0,
    area_name: '',
    area_phone: '',
    find_car_url: '',
    is_tax: 0,
  });

  const removeBillDetail = () => setBillDetail({
    bill_id: '',
    bill_licenseplate: '',
    bill_parking_hour: 0,
    bill_rate: 0,
    bill_amount: 0,
    bill_lot_code: '',
    bill_token: '',
    payment_status: 0,
    user_id: '',
    name: '',
    operator_code: '',
    bill_lot_name: '',
    lot_entering_time: '',
    common_payment_info: {},
    bill_detail: [],
    error_message: '',
    lot_allow_exit_time: '',
  });

  const updateBillDetail = useCallback(
    ({
      bill_id = '',
      bill_licenseplate = '',
      bill_parking_hour = 0,
      bill_rate = 0,
      bill_amount = 0,
      bill_lot_code = '',
      bill_token = '',
      payment_status = BillPaymentStatus.UnPaid,
      user_id = '',
      name = '',
      operator_code = '',
      bill_lot_name = '',
      lot_entering_time = '',
      common_payment_info = {},
      bill_detail = [],
      error_message = '',
      lot_allow_exit_time = '',
    }: Partial<BillDetail>) => {
      setBillDetail({
        ...billDetail,
        ...(bill_id && { bill_id }),
        ...(bill_licenseplate && { bill_licenseplate }),
        ...(bill_parking_hour && { bill_parking_hour }),
        ...(bill_rate && { bill_rate }),
        ...(bill_amount && { bill_amount }),
        ...(bill_lot_code && { bill_lot_code }),
        ...(bill_token && { bill_token }),
        ...(payment_status && { payment_status }),
        ...(user_id && { user_id }),
        ...(name && { name }),
        ...(operator_code && { operator_code }),
        ...(bill_lot_name && { bill_lot_name }),
        ...(lot_entering_time && { lot_entering_time }),
        ...(common_payment_info && { common_payment_info }),
        ...(bill_detail && { bill_detail }),
        ...(error_message && { error_message }),
        ...(lot_allow_exit_time && { lot_allow_exit_time }),
      });
    },
    [billDetail],
  );

  const [currentStep, setCurrentStep] = useState<PaymentSteps | RentSteps | CitySteps>(PaymentSteps.SearchCar);
  const { type = ApplicationType.Pay } = useParams() as { type: string };
  const navigate = useNavigate();
  const location = useLocation();
  const stepList = typeMapping[type];

  const nextStep = () => {
    const currentIndex = stepList.findIndex(({ step }) => step === currentStep);
    const nextStep = stepList[currentIndex + 1];

    if (!nextStep) return;

    setCurrentStep(nextStep.step);
    const nextPath = nextStep.path;
    navigate(`/${type}${nextPath}`);
  }

  const fetchInformation = useCallback(async ({ lot_code = '', operator_code = '', area_id = '' }: { lot_code?: any, operator_code?: any, area_id?: any }) => {
    try {

      let response;
      if (type === ApplicationType.City) {
        response = await searchAreaAPI({
          area_id,
        })

      } else {
        response = await fetchInformationAPI({
          lot_code,
          operator_code,
        });
      }

      const information = await response.json() as ParkingInformation;
      setInformation(information);

      if (type === ApplicationType.City) {
        localStorage.setItem('lot_code', information.area_id.toString());
      } else {
        localStorage.setItem('lot_code', type === ApplicationType.Pay ? lot_code : operator_code);
      }

      localStorage.setItem('lot_name', type === ApplicationType.City ? information.area_name : information.lot_name);
      localStorage.setItem('lot_phone', type === ApplicationType.City ? information.area_phone : information.lot_phone);
      localStorage.setItem('paying_note', information.after_paying_page_note);
      localStorage.setItem('operator', information.operator);

      // rent error return 400
      if (!response.ok) {
        navigate('/system-error');
      }
    } catch (error) {
      // pay error return 502
      navigate('/system-error');
      return;
    }
  }, [navigate, type]);

  useEffect(() => {
    const { pathname } = location;
    // ex: pay/select-payment
    //     rent/check-rent/123
    //     pay/search-car/12345678
    const stepPath = pathname.split('/')[2];

    if (!stepPath) navigate('/not-found');
    if (!stepList) return;

    const nextCurrentStep: Step = stepList.find(({ processPaths, path }) => {
      if (!!processPaths && Array.isArray(processPaths)) {
        return processPaths.join().includes(stepPath.toLowerCase());
      }
      return path === `/${stepPath.toLowerCase()}`;
    }) || typeMapping.pay[0];

    setCurrentStep(nextCurrentStep.step);
  }, [location, currentStep, navigate, stepList]);

  const localName = localStorage.getItem('lot_name') as string;
  const lotName = parkingInformation.lot_name ? parkingInformation.lot_name : localName;
  const fullTitle = `${lotName}-${type === ApplicationType.Rent ? '車位租用繳費' : '行動繳費機'}`;
  const title = type === ApplicationType.City ? lotName : fullTitle;
  const operator = parkingInformation.operator ? parkingInformation.operator : localStorage.getItem('operator');
  const lot_phone = parkingInformation.lot_phone ? parkingInformation.lot_phone : localStorage.getItem('lot_phone');
  const footerInfo = `${operator} ${lot_phone}`;
  // const pathcode = location.pathname.split('/')[3] as string;

  return (
    <GlobalContext.Provider value={{
      isLoading,
      billDetail,
      parkingInformation,
      isAppleDevice,
      setIsLoading,
      removeBillDetail,
      updateBillDetail,
      fetchInformation,
      setIsAppleDevice
    }}>
      <StepContext.Provider value={{ setCurrentStep, currentStep, nextStep }}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Navbar title={title} />
        <Progress stepList={stepList} currentStep={currentStep} />
        <Routes>
          {
            // 萬芳醫院
            // pathcode === '76303216' ?
            //   <>
            //     <Route path="search-car/:code" element={<SelectDevice />} />
            //     <Route path="search-license/:code" element={<SearchCar />} />
            //   </>
            //   :
            <Route path="search-car/:code" element={<SearchCar />} />
          }
          <Route path="select-payment" element={< SelectPayment />} />
          <Route path="success" element={< ResultSuccess />} />
          <Route path="failed" element={< ResultFailed />} />
          <Route path="check-rent/:code" element={< CheckRent />} />
          <Route path="bill-confirm" element={< BillConfirm />} />
          <Route path="result" element={< Result />} />
          <Route path="system-error" element={<Navigate to="/system-error" />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
        <Footer contact={footerInfo} />
        <Loading isLoading={isLoading} />
        <ToastContainer
          position="top-center"
          theme="colored"
          hideProgressBar
          draggablePercent={40}
          style={{ minWidth: "400px" }}
        />
      </StepContext.Provider>
    </GlobalContext.Provider>
  );
}

export default App;
